<template>
  <div class="block-expansion-panels">
    <BaseExpansionPanel
      v-for="(item, i) in list"
      :key="i"
      :is-html="isHtml"
      :flat="flat"
      :header="item.question"
      :content="item.answer"
      :force-expand="item.expand"
      handle-type="manuel"
      @toggleExpand="toggleExpand(i, ...arguments)"
    />
  </div>
</template>

<script>
export default {
  name: 'BlockExpansionPanels',
  props: {
    items: {
      type: Array,
      default: () => []
    },
    isHtml: {
      type: Boolean,
      default: false
    },
    flat: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      list: []
    }
  },
  watch: {
    items () {
      this.list = this.items.map(v => ({ ...v, expand: false }))
    }
  },
  mounted () {
    this.list = this.items.map(v => ({ ...v, expand: false }))
  },
  methods: {
    toggleExpand (key, value) {
      this.list = this.list.map(v => ({ ...v, expand: false }))
      this.list[key].expand = value
    }
  }
}
</script>

<style lang="scss" scoped>
.block-expansion-panels {
  @apply lg:space-y-4 space-y-2;
}
</style>
